import { useState, useEffect } from "react";
import { AxiosController } from "../../api/axiosController";
import { ApiResponse } from "../../types/apiResponseTypes";
import DetailLayout from "../../components/layout/DetailLayout";
import DetailList from "../../components/DetailList";
import categoryItems from "../../data/categoryItems";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"; // useSearchParams 추가
import { CircularProgress, Pagination } from "@mui/material";
import { ReadNormalBoardItem } from "../../types/ReadNormalBoardItem";

const GlampingTent: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const controller = new AxiosController();

  const [searchParams, setSearchParams] = useSearchParams(); // useSearchParams 추가

  const [data, setData] = useState<ReadNormalBoardItem[]>([]);
  const [loading, setLoading] = useState(true);
  const [activeIndex, setActiveIndex] = useState<number>(
    location.state?.activeIndex ?? 0
  );
  const [totalCount, setTotalCount] = useState(0);

  // URL에서 페이지 번호를 가져옴, 없으면 기본적으로 1번 페이지
  const pageFromURL = parseInt(searchParams.get("page") || "1", 10);
  const [currentPage, setCurrentPage] = useState(pageFromURL);

  // 데이터 가져오는 함수
  const fetchData = async (item: string, page: number) => {
    setLoading(true);

    try {
      const url =
        item === "정통형"
          ? "GlampingTentlegitimacy"
          : "GlampingTentSpecialorder";

      const response: ApiResponse = await controller.get({
        url,
        params: {
          PageNo: page,
        },
      });

      if (response.IsSuccess) {
        setData(response.Message.ReadNormalBoardList);
        setTotalCount(response.Message.TotalCount ?? 0);
      }
    } catch {
      navigate(`/Error`);
    } finally {
      setLoading(false);
    }
  };

  const handleCategory = (item: string, index: number) => {
    setActiveIndex(index);
    setCurrentPage(1); // 카테고리 변경 시 첫 페이지로 초기화
    setSearchParams({ page: "1" }); // 쿼리스트링도 첫 페이지로 설정

    if (item === "설치사례") {
      navigate(`/Installation/글램핑텐트 설치사례`);
    } else {
      navigate("/GlampingTentlegitimacy", { state: { activeIndex: index } });
    }
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setCurrentPage(page);
    setSearchParams({ page: String(page) }); // 페이지 변경 시 URL에 반영
  };

  // 데이터를 가져오고 카테고리를 처리하는 useEffect
  useEffect(() => {
    window.scrollTo(0, 0);

    setActiveIndex(location.state?.activeIndex ?? 0);

    // 선택된 카테고리에 따른 데이터 로드
    fetchData(
      categoryItems["글램핑텐트"].list[activeIndex] ?? "정통형",
      currentPage
    );
  }, [location.state, currentPage]);

  return (
    <DetailLayout title="글램핑 텐트" img={data[0]?.ImgList[0]}>
      <div className="relative top-[-140px] w-full flex flex-col justify-center items-center z-40 rounded-lg xs:h-[90px] sm:h-[90px] md:h-[100px] lg:h-[100px]">
        <div className="flex flex-row w-[70%] h-full text-center rounded-lg justify-evenly items-center xxs:text-xs xs:text-xs sm:text-sm md:text-md lg:text-xl">
          {categoryItems["글램핑텐트"].list.map(
            (item: string, index: number) => (
              <p
                key={index}
                className={`flex text-center  rounded-xl border-gray-300 h-full justify-center items-center shadow-lg text-base md:text-xl lg:text-2xl ${
                  activeIndex === index
                    ? "text-black bg-blue-200"
                    : "bg-white rounded-lg hover:bg-blue-200"
                } ${item.length < 5 ? "w-2/5" : "w-3/4"}`}
                onClick={() => handleCategory(item, index)}
              >
                {item}
              </p>
            )
          )}
        </div>
      </div>
      {loading ? (
        <CircularProgress />
      ) : (
        <div className="flex flex-col relative top-[-100px] justify-center items-center w-full">
          <DetailList
            data={data}
            title={categoryItems["글램핑텐트"].list[activeIndex]}
          />
          <Pagination
            count={Math.ceil(totalCount / 12)}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
            variant="outlined"
            shape="rounded"
            sx={{ marginTop: "30px" }}
          />
        </div>
      )}
    </DetailLayout>
  );
};

export default GlampingTent;
