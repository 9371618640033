import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AxiosController } from "../../api/axiosController";
import { ApiResponse } from "../../types/apiResponseTypes";
import DetailLayout from "../../components/layout/DetailLayout";
import SideBar from "../../components/SideBar";
import DetailView from "../../components/DetailView";

const IndianDetail = () => {
  const navigate = useNavigate();
  const { PostId } = useParams<{
    PostId: string;
  }>();
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  const controller = new AxiosController();

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchTentData = async () => {
      try {
        const response = await controller.get({
          url: "Indian",
          params: {
            PostId: PostId,
          },
        });

        const resData = response as ApiResponse;

        if (resData.IsSuccess) {
          setData(
            resData.Message.ReadNormalBoardList.find(
              (item) => item.RowKey === PostId
            )
          );
        }
      } catch (error) {
        navigate(`/Error`);
      } finally {
        setLoading(false);
      }
    };

    fetchTentData();
  }, [PostId, navigate]);

  return (
    <DetailLayout title="인디언, 게르 텐트">
      <div className="flex flex-col w-full justify-center items-center">
        {loading ? (
          <div>로딩 중...</div>
        ) : data ? (
          <>
            <SideBar />
            <DetailView data={data} />
            <button
              onClick={() => navigate(-1)}
              className="mt-4 p-2 w-[20%] justify-start items-start bg-blue-400 text-white rounded text-base sm:text-lg"
            >
              목록보기
            </button>
          </>
        ) : (
          <div>데이터를 불러올 수 없습니다.</div>
        )}
      </div>
    </DetailLayout>
  );
};

export default IndianDetail;
