import Main1 from "../assets/photoSlider/1.jpg";
import Main2 from "../assets/photoSlider/2.jpg";

const photoSlider = [
  {
    src: Main1,
    alt: "Main Background 1",
  },
  {
    src: Main2,
    alt: "Main Background 2",
  },
];

export default photoSlider;
